import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import "../css/typography.css"
import Madrid from "../images/contacto/Madrid.webp"
import useWindowSize from "../hooks/useWindowSize"
import TitleBackground from "../images/contacto/Background.webp"
import Calendar from "../components/contacto/calendar"
import Card from "../components/contacto/card"
import Tel from "../images/contacto/Tel.webp"
//import Loc from "../images/contacto/Loc.png"
import Mail from "../images/contacto/Mail.webp"
import Enviar from "../components/buttons/enviar"
import { Helmet } from 'react-helmet'

const FirstSection = styled.div`
    background-image: url(${Madrid});
    background-size: cover;
    margin: 0;
    color: white;
    padding: 5% 0 5% 0;
    min-height: 30vh;
    display: flex;
    align-items: center;

    >div:nth-child(1) {
        width: 450px;
        background-image: url(${TitleBackground});
        background-size: cover;
        font-size: 15.5px;
        font-family: 'M Extra Bold';
        padding: .1% 0 .1% 3%;
    }

    @media only screen and (min-width: 1800px) {
        >div:nth-child(1) {
            width: 750px;
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px) {
        >div:nth-child(1) {
            width: 900px;
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px) {
        >div:nth-child(1) {
            width: 1050px;
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px) {
        >div:nth-child(1) {
            width: 1250px;
            font-size: 58px;
        }
    }

    @media only screen and (max-width: 885px) {
        >div:nth-child(1) {
            width: 750px;
            font-size: 14.5px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 736px) {
        >div:nth-child(1) {
            width: 580px;
            font-size: 11px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 570px) {
        width: 100vw;

        >div:nth-child(1) {
            width: 99%;
            font-size: 3vw;
            padding: .1% 0 .1% 3%;
            background-image: url(${TitleBackground});
            background-position-x: 100%;
        }
        >div:nth-child(1) h1 {
            width: 92%;
        }
    }
`

const SecondSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h2 {
        font-family: 'M Regular';
        font-size: 23px;
        width: 350px;
        color: #0B2A3B;
        text-align: center;
        margin-top: 13%;
    }

    span {
        font-family: 'M Extra Bold';
    }

    >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2%;
    }

    @media only screen and (min-width: 1200px) {
            
            >div:nth-child(1) h2{
                font-size: 25px;
                width: 420px;
                
                }
        }
    
    @media only screen and (min-width: 1800px) {
            
        >div:nth-child(1) h2{
            font-size: 40px;
            width: 420px;
            }
    }
    @media only screen and (min-width: 2500px)  {

        >div:nth-child(1) h2{
            font-size: 60px;
            width: 820px;
            }
    }    
    @media only screen and (min-width: 3500px)  {

        >div:nth-child(1) h2{
            font-size: 68px;
            width: 820px;
            }

    }
    @media only screen and (min-width: 4000px)  {

        >div:nth-child(1) h2{
            font-size: 82px;
            width: 1100px;
            }
    }

`

const SeparatorDark = styled.div`
    width: 50px;
    border-bottom: 3px solid #0B2A3B;
    border-radius: 50px;

    @media only screen and (min-width: 1200px) {
        
        width: 80px;
        margin-bottom: 50px;  
    }
    @media only screen and (min-width: 1800px) {
        
        width: 120px;
        margin-bottom: 100px;  
    }
    @media only screen and (min-width: 2500px) {
        
        width: 200px;
        margin-bottom: 150px;  
    }
    @media only screen and (min-width: 3500px) {
        
        width: 250px;
        margin-bottom: 200px;  
    }
` 

const ThirdSection = styled.div`
    display: flex;
    justify-content: center;
`

const FourthSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #0B2A3B;
    padding: 4% 0 4% 0;
    min-height: 150px;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        padding-top: 10vw;
    }
`

const FifthSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
    }

    input {
        margin-bottom: 20px;
        height: 40px;
        width: 500px;
        background-color: #EBEBEB;
        border: none;
    }

    textarea {
        background-color: #EBEBEB;
        border: none;
        margin-bottom: 10%;
    }

    h4 {
        font-family: 'M Bold';
        color: #0B2A3B;
        font-size: 22px;
        text-align: center;
    }

    @media only screen and (min-width: 1200px) {
         h4 {
            width: 700px;
            font-size: 25px;
        }
        input{
            width: 600px;
            height: 80px;
            font-size: 25px;
        }
        textarea{
            width: 600px;
            height: 150px;
            font-size: 25px;
        }
    }
    @media only screen and (min-width: 1800px) {
         h4 {
            width: 950px;
            font-size: 40px;
        }
        input{
            width: 600px;
            height: 80px;
        }
        textarea{
            width: 600px;
            height: 150px;
        }
    }
    @media only screen and (min-width: 2500px) {
        h4 {
            width: 1200px;
            font-size: 60px;
        }
        input{
            width: 900px;
            height: 110px;
            font-size:30px;
        }
        textarea{
            width: 900px;
            height: 250px;
            font-size:30px;
        }
    }
    @media only screen and (min-width: 3500px) {
        h4 {
            width: 1450px;
            font-size: 68px;
        }
        input{
            width: 1100px;
            height: 120px;
            font-size:40px;
            margin-bottom: 30px;
        }
        textarea{
            width: 1100px;
            height: 300px;
            font-size:40px;
        }
    }
    @media only screen and (min-width: 4000px) {
        h4 {
            width: 1650px;
            font-size: 82px;
        }
        input{
            width: 1300px;
            height: 130px;
            font-size:50px;
            margin-bottom: 40px;
        }
        textarea{
            width: 1300px;
            height: 400px;
            font-size:50px;
        }
        >div:nth-child(2) div.enviar {
            font-size: 50px;
        }
    }

    @media only screen and (max-width: 513px) {
        input {
            width: 90vw;
        }
    }
`

const IndexPage = () => {
    const windowSize = useWindowSize();
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Helmet>
                <title>Contáctanos para adquirir bienes inmuebles en España</title>
                <meta name="description" content="Te ayudamos a encontrar las mejores oportunidades de inversión de bienes inmuebles en España y te asesoramos en cada momento."/>
            </Helmet>
            <FirstSection title="Invierte en bienes raíces en el extranjero.">
                <div>
                    <h1>CONTACTO</h1>
                </div>
            </FirstSection>
            <SecondSection>
            <div>
                <h2>AGENDA <span>UNA CITA</span></h2>
                <SeparatorDark />
            </div>
            </SecondSection>
            <ThirdSection>
                <Calendar />
            </ThirdSection>
            <FourthSection>
                <Card url='tel:5580452327' text='55 8045 2327' image={Tel} />
                <Card url='mailto:espana@ingezar.com.mx' text='espana@ingezar.com.mx' image={Mail} />
                {/* <Card url='' text='Dirección' image={Loc} /> */}
            </FourthSection>
            <FifthSection>
                <h4>¿Tienes alguna duda o comentario? ¡Contáctanos!</h4>
                <form 
                    action="https://formspree.io/f/xgednjov"
                    method="POST"
                >
                    <input type="text" name='nombre' placeholder="Nombre"  style={{fontFamily: 'M Medium', paddingLeft: '2%'}}/>
                    <input type="text" name="_replyto" placeholder="Correo"  style={{fontFamily: 'M Medium', paddingLeft: '2%'}}/>
                    <input type="text" name='teléfono' placeholder="Teléfono con clave lada"  style={{fontFamily: 'M Medium', paddingLeft: '2%'}}/>
                    <textarea name="mensaje" cols="30" rows="10" placeholder="Mensaje" style={{fontFamily: 'M Medium', paddingLeft: '2%'}}/>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <Enviar title='Enviar' />
                    </div>
                </form>
            </FifthSection>
        </Layout>
    ): (null)}

export default IndexPage
