import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const Button = styled.div`

    font-family: "H Bold";
    font-size: 17px;
    color: white;
    text-decoration: none;
    position:relative;
    color: white;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    

    a {
        text-decoration: none;
        color: white;
    }

    :before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: #808083;
        width: 100%;
        height: 100%;
        transition: .2s;
        -webkit-transform: skewX(-10deg);
        -moz-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
    }

`

const MainDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 20%;
    cursor: pointer;

    input {
        background: #808083 !important;
        color: white;
        font-family: "H Bold";
        font-size: 17px;
        cursor: pointer;    
    }

    @media only screen and (min-width: 3500px){

        input {
            font-size: 40px;
        }
    }
`

const ButtonComponent = () => {

    return (
        <MainDiv>
            <input type="submit" value='Enviar' />
        </MainDiv>
    )
}

export default ButtonComponent
