import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const MainDiv = styled.div`
    font-family: 'M Medium';
    font-size: 17px;
    display: flex;
    align-items: center;
    background-color: white;
    width: 300px;
    padding: 10px 10px 10px 20px;

    a {
        text-decoration: none;
        color: #000000;    
    }

    img {
        width: 50px;
        margin-right: 10px;
    }
    
    @media screen and (min-width: 1200px) {
       
       width: 390px;

       a {
           font-size: 25px;
           width: 350px;
       }
       img{
           width: 50px;
       }

        
    }
    @media screen and (min-width: 1800px) {

        width: 520px;
        height: 80px;

        a{
           font-size: 30px;
           width: 550px;
           margin-left: 20px;
       }
       img{
           margin-left: 20px;
           width: 70px;
           
       }
    }
    @media screen and (min-width: 2500px) {

        width: 680px;
        height: 120px;

        a{
           font-size: 40px;
           width: 700px;
           margin-left: 40px;
       }
       img{
           width: 80px;
           
       }
    }
    @media screen and (min-width: 3500px) {
        
        width: 900px;
        height: 180px;

        a{
           font-size: 50px;
           width: 890px;
       }
       img{
           width: 100px;
           
       }
    }
    @media screen and (min-width: 4000px) {
               
        width: 1050px;
        height: 220px;

        a {
           font-size: 60px;
           width: 1290px;

       }
       img{
           width: 150px;
           
       }
    }

    @media only screen and (max-width: 1000px) {
        margin-bottom: 20px;
        width: 80vw;
    }
`

const Card = (props) => {

    return (
        <MainDiv>
            <img src={props.image} alt='icono' />
            <a href={props.url} target='_blank' >{props.text}</a>
        </MainDiv>
    )
}

export default Card
